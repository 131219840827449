
.table-sticky-choose-person{
    position: relative;
    height: 19rem;
    overflow: auto;
    table {
        thead {
            background: blue;
            color: white;
            position: sticky;
            top: 0;
            z-index: 10;
            tr {
                th {
                    top: 0;
                    position: sticky;
                    z-index: 10;
                    color: #fff;
                    text-align: center;
                    vertical-align: middle;
                    background-color: #006fca;
                }
            }
        }
        tbody {
            position: relative;
            tr {
                td {
                    vertical-align: middle;
                    position: sticky;
                }
                th {
                  
                  &:nth-of-type(1) { top: 0;left: 0;position: sticky;background-color: #d2dada;background-clip: padding-box;z-index: 9;}
                  &:nth-of-type(2) { top: 0;left: 31px !important;position: sticky;background-color:#d2dada;background-clip: padding-box;z-index: 9;}
                  
                }
                
            }
        }
        input[type="number"] { min-width: 100px; }
    }
  }