// stylelint-disable property-no-vendor-prefix
html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  overflow-y: auto;
  overflow-x: hidden;
  letter-spacing: $body-letter-spacing;
  touch-action: manipulation;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga" 0;
  position: relative;

  .content * {
    @include scrollbar;
  }

  @media print {
    background: transparent;
  }
}

