.bG9hZGluZw4401c506776944b0 {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.06);
  opacity: 0;
  visibility: hidden;
  transform: scaleX(1.1) scaleY(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  font-family: sans-serif;
  z-index: 9999;
}

.bG9hZGluZwdc1575b88e7648a0b8605302c33735d3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  opacity: 0.5;
  padding: 1rem 1.5rem;
  width: auto;
  border-radius: 0.5rem;
}

.bG9hZGluZwdc2819cea95b44372a535d1ba3 {
  opacity: 1;
  visibility: visible;
  transform: scaleX(1) scaleY(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}
