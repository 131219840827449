/**
Icon input
 */
.input-icon {
  position: relative;

  .form-control:not(:last-child) {
    padding-right: 2.5rem;
  }

  .form-control:not(:first-child) {
    padding-left: 2.5rem;
  }
}

.input-icon-addon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2.5rem;
  color: $text-muted;
  pointer-events: none;
  font-size: 1.2em;

  &:last-child {
    right: 0;
    left: auto;
  }
}
