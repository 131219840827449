.pt-xl-0 {
  padding-top: 0px !important;
  .card {
    padding: 25px !important;
    border-color: #eaecec;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    a {
      text-decoration: none;
      color: #22af47;
      -webkit-transition: color 0.2s ease;
      -moz-transition: color 0.2s ease;
      transition: color 0.2s ease;
      margin-bottom: 45px !important;
    }
    form {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      vertical-align: baseline;
      .display-4 {
        font-size: 2.5rem;
      }
      .mb-30 {
        margin-bottom: 30px !important;
      }
      .form-group {
        margin-bottom: 1rem;
        .input-group {
          position: relative;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -ms-flex-align: stretch;
          align-items: stretch;
          width: 100%;
        }
      }
    }
  }
}
