.badge {
  @include subheader(false);
  // display: inline-flex;
  // justify-content: center;
  // background: $gray-500;
  overflow: hidden;
  vertical-align: text-bottom;
  user-select: none;
  
  @at-root a#{&} {
    color: $white;
  }

  &:empty {
    display: inline-block;
    width: $badge-empty-size;
    height: $badge-empty-size;
    min-width: 0;
    min-height: auto;
    padding: 0;
    border-radius: 50%;
  }
}

.badge-up {
  position: absolute;
  top: 25%;
  right: 0;
  min-width: 1.75em;
  min-height: 1.75em;
  padding: 0 .25rem;
  line-height: 1rem;
  background-clip: padding-box;
  transform: translate(0%, -50%);
}

//bordered
.badge-outline {
  background-color: transparent;
  border: 1px solid currentColor;
}

.badge-pill {
  border-radius: 100px;
  min-width: 1.75em;
}

.badge-avatar {
  box-sizing: content-box;
  width: 1.75em;
  margin: 0 .5em 0 -.5em;
  color: $light-white;
  background: $light-black no-repeat center/cover;
  vertical-align: text-bottom;
}

.badge-addon {
  padding: 0 .5rem;
  margin: 0 -.5em 0 .5em;
  min-width: 1.75em;
  background: $light-black;
  text-align: center;
  color: $white;
  text-decoration: none;
}