.btn {
  font-weight: $btn-font-weight;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:not([class^="btn-outline"]):not([class*=" btn-outline"]):not([class^="btn-ghost"]):not([class*=" btn-ghost"]) {
    &:not(:focus):not(.focus) {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .05), inset 0 2px 0 -1px rgba(255, 255, 255, .1);
    }

    svg.icon {
      filter: drop-shadow(1px 1px 0 rgba(0, 0, 0, .05));
    }
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
  }

  .icon {
    margin: 0 .25em 0 -.25em;
    font-size: calc(20em/14);
    vertical-align: sub;
  }

  .avatar {
    width: 1.25rem;
    height: 1.25rem;
    vertical-align: text-top;
    margin: 0 .5em 0 -.25em;
  }

  .icon-right {
    margin: 0 -.25em 0 .5em;
  }

  svg.icon {
    width: 1em;
    height: 1em;
    stroke-width: 1.75;
  }
}

.btn-white {
  @include button-variant($white, $border-color, $dark);
  background-image: linear-gradient(-180deg, rgba($dark, 0), rgba($dark, .04) 100%);
  box-shadow: none !important;

  &.active {
    background: $primary;
    color: $white;
    z-index: 3 !important;
  }
}

.btn-lg {
  svg.icon {
    stroke-width: 2;
  }
}

.btn-pill {
  padding-right: 1.5em;
  padding-left: 1.5em;
  border-radius: 10rem;

  &[class*="btn-icon"] {
    padding: 0.375rem 15px;
  }
}

.btn-icon {
  min-width: add($btn-line-height * $btn-font-size + $btn-padding-y * 2, $btn-border-width * 2);

  &.btn-sm {
    min-width: add($btn-line-height * $btn-font-size-sm + $btn-padding-y-sm * 2, $btn-border-width * 2);
  }

  .icon {
    margin: 0 -1em;
  }
}

// stylelint-disable declaration-no-important
.btn-link {
  box-shadow: none !important;
}

.btn-square {
  border-radius: 0;
}

.btn-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -.5rem !important;
  margin-right: -.5rem;
  line-height: 1;

  >.btn,
  >.dropdown {
    margin: 0 .5rem .5rem 0;
  }

  >.btn-block {
    flex: 1;
    white-space: nowrap;
  }
}

.btn-options {
  display: flex;
  align-items: center;
  padding: 0;
  color: $text-muted;
  cursor: pointer;
  background: none;
  border: none;

  &:focus {
    outline: 0;
  }

  .icon {
    width: 1rem;
    height: 1rem;
  }
}

.btn-floating {
  position: fixed;
  z-index: $zindex-fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  border-radius: 50%;
}

.btn-loading {
  position: relative;
  color: transparent !important;
  text-shadow: none !important;
  pointer-events: none;

  &:after {
    content: '';
    display: inline-block;
    vertical-align: text-bottom;
    border: $spinner-border-width-sm solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    color: $white;
    position: absolute;
    width: 1rem;
    height: 1rem;
    left: calc(50% - .5rem);
    top: calc(50% - .5rem);
    animation: spinner-border .75s linear infinite;
  }
}