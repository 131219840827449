.dropdown-menu {
  box-shadow: $box-shadow;
  user-select: none;
  margin: 0;

  &.card {
    padding: 0;
    min-width: $dropdown-menu-max-width;
    display: none;

    &.show {
      display: flex;
    }
  }
}

.dropdown-item {
  min-width: $dropdown-min-width;
  color: inherit;
  display: flex;
  align-items: center;
  margin: 0;
  line-height: $line-height-base;
}

.dropdown-item-icon {
  width: 1rem !important;
  font-size: 1rem !important;
  margin-right: .5rem;
  opacity: .64;
  text-align: center;
  stroke-width: 2px;
}

.dropdown-header {
  @include subheader;
  padding-bottom: .25rem;
  pointer-events: none;
}

.dropdown-menu-scrollable {
  height: auto;
  max-height: 12rem;
  overflow-x: hidden;
}

.dropdown-menu-columns {
  columns: 2;
  column-gap: 0;

  .dropdown-item {
    display: inline-flex;
    min-width: 10rem;
  }
}

@for $i from 2 through 4 {
  .dropdown-menu-columns-#{$i} {
    columns: $i;
  }
}

.dropdown-menu-arrow {
  margin-top: .5rem;

  &:before {
    content: "";
    position: absolute;
    top: -4px;
    left: 12px;
    display: block;
    background: inherit;
    width: 14px;
    height: 14px;
    transform: rotate(45deg);
    transform-origin: center;
    border: 1px solid;
    border-color: inherit;
    z-index: -1;
    clip: rect(0px, 9px, 9px, 0px);
  }

  &.dropdown-menu-right {
    &:before {
      right: 12px;
      left: auto;
    }
  }
}

.dropright {
  >.dropdown-menu {
    margin-top: subtract(-$dropdown-padding-y, 1px);
    margin-left: 0;
  }

  .dropdown-toggle {
    &:after {
      margin-left: auto;
    }
  }
}

.dropdown-menu-card {
  padding: 0;

  >.card {
    margin: 0;
    border: 0;
    box-shadow: none;
  }
}