
// Thead color context
.table {
  @each $name, $color in join($colors,$theme-colors) {
    .thead-#{$name} {
      th {
        color: color-yiq($color);
        background-color: $color;
        border-color: darken($color, 7.5%);
      }
    }
  }
}
.gdt-table {
  min-height: 40px !important;
  background-color: $primary;
  border-radius: .4rem .4rem 0 0;
  @media (max-width: 400px) {
    display: grid !important;
  }
}