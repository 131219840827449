.alert-avatar {
  padding-left: 3.75rem;

  .avatar {
    position: absolute;
    top: .5rem;
    left: .75rem;
  }
}

.alert{
  a{
    display: inline-block;
    height:100%;
    vertical-align: middle;

    .icon {
      height: 100%;
    }
  }
}