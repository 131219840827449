.log-activity {
    height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;

    hr {
        margin: 1em 0 1em 0;
        border: none;
        height: 1px;
        background: #eee;
    }

    h2 {
        font-size: 1rem;
        font-weight: 800;
        margin: 0.5rem 0.5rem 0.5rem 0;
    }

    p {
        overflow-wrap: break-word;
    }

    li,
    figcaption,
    label {
        font-size: 1rem;
        margin: 0.5rem;
        overflow-wrap: break-word;
    }

    .container {
        max-width: calc(100% - 3rem);
        width: 75rem;
        margin: 0.5rem auto;
    }

    section {
        text-align: center;
        background: skyblue;
        margin: 0;
        padding: 0.5rem;
        box-shadow: 0 0 1rem rgb(0 0 0 / 30%);
        border-radius: 0.5rem;
        position: sticky;
        top: 0px;
        z-index: 1000;
    }

    section h1 {
        margin-bottom: 0px;
        font-size: 1.5rem;
        font-weight: 900;
        text-shadow: .1rem .1rem #fff, -.1rem .1rem #fff, .1rem -.1rem #fff, -.1rem -.1rem #fff, .1rem .1rem .5rem #000;
    }

    .timeline {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        position: relative;
    }

    .timeline:before {
        border: dotted silver;
        border-width: 0 0 0 .2rem;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        width: 0;
    }

    .block {
        background: #fff;
        padding: 1rem;
        box-shadow: 0 0 1rem rgba(0, 0, 0, .3);
        border-radius: .5rem;
        width: -webkit-fill-available;

        .detail {
            cursor: pointer;

            div {
                color: skyblue;
                padding: 0.2rem;
                border-radius: 5px;
                box-shadow: 0 0 0.2rem rgb(0 0 0 / 14%);
                background: aliceblue;
                width: fit-content;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }

    @media (min-width: 40em) {
        .timeline:before {
            left: 50%;
        }
    }

    .row {
        margin: 1rem 0;
        padding-left: 1.5rem;
        position: relative;
        width: 100%;
    }

    .row:before,
    .row:after {
        border: solid #999;
        content: "";
        left: 0;
        position: absolute;
        top: 2rem;
        animation: slide-in-blurred-left 1s cubic-bezier(0.47, 0.47, 0.51, 0.53);
    }

    .row:before {
        border-width: .3rem 0 0;
        transform: translateY(-50%);
        width: 1.4rem;
    }

    .row:after {
        background: #fff;
        border-radius: 50%;
        border-width: .3rem;
        height: 1rem;
        transform: translate(-50%, -50%);
        width: 1rem;
    }

    @media (min-width: 40em) {
        .row {
            width: 50%;
        }

        .row:nth-child(odd) {
            margin-bottom: 1.5rem;
            padding-left: 0;
            padding-right: 1.5rem;
        }

        .row:nth-child(even) {
            margin-top: 1.5em;
        }

        .row:nth-child(odd):before {
            left: auto;
            right: 0;
        }

        .row:nth-child(odd):after {
            left: 100%;
        }

        .row:nth-child(even):before {
            top: auto;
            bottom: 1.7rem;
        }

        .row:nth-child(even):after {
            top: auto;
            bottom: 1rem;
        }
    }

    .MuiAccordionDetails-root {
        padding: 0px;
    }

    .MuiAccordionSummary-content {
        margin: 0px;
    }

    .MuiButtonBase-root {
        padding: 0px;
    }

    .MuiPaper-elevation1 {
        box-shadow: none;
    }

    .MuiIconButton-edgeEnd {
        margin-right: 0px;
    }

}

.slide-in-blurred-left {
    -webkit-animation: slide-in-blurred-left 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    animation: slide-in-blurred-left 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

.slide-in-blurred-right {
    -webkit-animation: slide-in-blurred-right 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    animation: slide-in-blurred-right 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

@-webkit-keyframes slide-in-blurred-left {
    0% {
        -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) scaleY(1) scaleX(1);
        transform: translateX(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes slide-in-blurred-left {
    0% {
        -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) scaleY(1) scaleX(1);
        transform: translateX(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

@-webkit-keyframes slide-in-blurred-right {
    0% {
        -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
        transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) scaleY(1) scaleX(1);
        transform: translateX(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes slide-in-blurred-right {
    0% {
        -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
        transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) scaleY(1) scaleX(1);
        transform: translateX(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

// -------------------------------------------
@keyframes slide-in-blurred-left-after {
    0% {
        -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) scaleY(1) scaleX(1);
        transform: translateX(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

@-webkit-keyframes slide-in-blurred-left-after {
    0% {
        -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) scaleY(1) scaleX(1);
        transform: translateX(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}