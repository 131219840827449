.stars {
  display: inline-flex;
  color: $gray-400;
  font-size: $h5-font-size;

  .star:not(:first-child) {
    margin-left: .25rem;
  }
}

.star {

}
