

canvas, .canvas-container {
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.toolbar {
    width: 100%;
    background-color: rgb(50, 54, 57);
    height: 50px;
    position: relative;
    top: 0;
    left: 0;
    z-index: 10;
    margin: 0px !important;
}

#pdf-container {
    padding-left: 10px;
    text-align: center;
    box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
    overflow-y: auto;
    max-height: 50rem;
}

button:focus {
    outline: 0;
}

.toolbar .tool {
    display: inline-block;
    padding-top: 8px;
    padding-left: 10px;
    margin-right: 5px;
    align-self: center;
}

.toolbar .tool label{
    color: white;
}
.toolbar .tool select,
.toolbar .tool input {
    display: inline-block;
    width: auto;
    height: auto !important;
    padding: 0;

}

.toolbar .tool input {
    width: 50px;
}

.toolbar .tool .color-tool {
    height: 25px;
    width: 25px;
    border-radius: 25px;
    border: 0;
    cursor: pointer;
    display: inline-block;
    margin-left: 0.3rem;
}

.toolbar .tool .color-tool.active {
    -webkit-box-shadow: 0px 0px 0px 3px rgb(255 255 255);
    -moz-box-shadow: 0px 0px 0px 3px rgb(255 255 255);
    box-shadow: 0px 0px 0px 3px rgb(255 255 255);
}

.toolbar .tool:nth-last-child(1) {
    float: right;
}

.toolbar .tool .tool-button {
    background-color: rgb(50, 54, 57);
    border: 1px solid rgb(50, 54, 57);
    color: #fff;
    cursor: pointer;
}

.toolbar .tool .tool-button:hover,
.toolbar .tool .tool-button.active {
    background-color: rgb(82, 86, 89);
    border-color: rgb(82, 86, 89);
}
