.table-sticky-vd {
    position: relative;
    height: 23rem;
    overflow: auto;

    table {
        thead {
            position: sticky;
            top: 0;
            z-index: 10;

            tr {
                td {
                    top: 0;
                    position: sticky;
                    z-index: 10;
                    text-align: center;
                    vertical-align: middle;
                    background-color: lightslategray;
                    color: white;
                    font-weight: bold;
                }
            }
        }

        tbody {
            position: relative;

            tr {
                td {
                    vertical-align: middle;
                    // position: sticky;
                    text-align: center;
                }

                th {
                    &:nth-of-type(1) {
                        top: 0;
                        left: 0;
                        position: sticky;
                        background-color: #d2dada;
                        background-clip: padding-box;
                        z-index: 9;
                    }

                    &:nth-of-type(2) {
                        top: 0;
                        left: 31px !important;
                        position: sticky;
                        background-color: #d2dada;
                        background-clip: padding-box;
                        z-index: 9;
                    }
                }
            }
        }

        input[type="number"] {
            min-width: 100px;
        }
    }
}

.progress-report {
    height: 5px;
    -webkit-mask: radial-gradient(circle closest-side, #c4bdbd 100%, #0000) left/20% 100%;
    background: linear-gradient(#939a93 0 0) left/0% 100% no-repeat #fff;
    animation: p7 2s infinite steps(6);
}

@keyframes p7 {
    100% {
        background-size: 120% 100%
    }
}

.action-pro {
    cursor: pointer;

    &:hover {
        background-color: gainsboro !important;
    }
}