/**
Bootstrap color input
 */
.form-control-color {
  &::-webkit-color-swatch {
    border: none;
  }
}

/**
Remove the cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

