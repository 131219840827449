//ASSETS BASE
$assets-base: ".." !default;

// FONTS
$google-font: "Ubuntu" !default;

$font-family-sans-serif: $google-font, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !default;
$font-family-monospace: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
$font-family-serif: "Georgia", "Times New Roman", times, serif !default;

// EXTERNAL FONTS
$icon-fonts: (
  ) !default;

//Fonts
$font-size-base: .875rem !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 900 !default;
$body-letter-spacing: -.01em !default;

$line-height-base: calc(1.5rem/$font-size-base) !default;
$line-height-sm: calc(1.25rem/$font-size-base) !default;
$line-height-lg: calc(2rem/$font-size-base) !default;

// COLORS
$theme-color-interval: 10% !default;
$min-contrast-ratio: 1.5 !default;

$light: #f5f7fb !default;
$dark: #354052 !default;

$light-black: rgba($dark, .24) !default;
$light-mix: rgba(mix($light, $dark, 64%), .24) !default;
$light-white: rgba($light, .24) !default;

$min-black: rgba($dark, .024) !default;
$min-white: rgba(mix($light, $dark, 48%), .1) !default;

$gray-50: #fbfbfb !default;
$gray-100: $light  !default;
$gray-200: mix($light, $dark, 98%) !default;
$gray-300: mix($light, $dark, 94%) !default;
$gray-400: mix($light, $dark, 88%) !default;
$gray-500: mix($light, $dark, 78%) !default;
$gray-600: mix($light, $dark, 60%) !default;
$gray-700: mix($light, $dark, 36%) !default;
$gray-800: mix($light, $dark, 16%) !default;
$gray-900: $dark  !default;

$blue: #206bc4 !default;
$azure: #45aaf2 !default;
$indigo: #6574cd !default;
$purple: #a55eea !default;
$pink: #f66d9b !default;
$red: #cd201f !default;
$orange: #ff922b !default;
$yellow: #fab005 !default;
$lime: #94d82d !default;
$green: #5eba00 !default;
$teal: #2bcbba !default;
$cyan: #17a2b8 !default;
$black: #000000 !default;
$white: #ffffff !default;

$body-bg: $light  !default;
$body-color: $dark  !default;

$text-muted-opacity: .72 !default; //Perfect color for AA
$text-muted: mix($body-color, #ffffff, $text-muted-opacity * 100%) !default;
$border-color: rgba($text-muted, .2) !default;
$border-color-light: rgba($text-muted, .1) !default;

$active-bg: rgba($maincolor, .06) !default;
$hover-bg: rgba($text-muted, .06) !default;

$primary: $maincolor  !default;
$secondary: $text-muted  !default;
$success: $green  !default;
$info: $azure  !default;
$warning: $yellow  !default;
$danger: $red  !default;

$social-colors: (
  "facebook": #3b5998,
  "twitter": #1da1f2,
  "google": #dc4e41,
  "youtube": #ff0000,
  "vimeo": #1ab7ea,
  "dribbble": #ea4c89,
  "github": #181717,
  "instagram": #e4405f,
  "pinterest": #bd081c,
  "vk": #6383a8,
  "rss": #ffa500,
  "flickr": #0063dc,
  "bitbucket": #0052cc,
  "tabler": #206bc4) !default;

$colors: (
  "primary":$maincolor,
  "blue": $blue,
  "azure": $azure,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "lime": $lime,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "gray": $gray-600,
  "gray-dark": $gray-800,
  "dark": $dark) !default;


/**
Dark mode
 */

$dark-mode-darken: darken($dark, 3%) !default;
$dark-mode-lighten: lighten($dark, 3%) !default;
$dark-mode-text: $light;

$avatar-size: 2rem !default;
$avatar-sizes: (
  "sm": 1.25rem,
  "md": 2.5rem,
  "lg": 4.5rem,
  "xl": 6rem) !default;


$link-decoration: none !default;

$border-width: 1px !default;
$border-width-wide: 2px !default;
$border-radius: .4rem !default;

//Typography
$headings-font-weight: $font-weight-bold  !default;

$h1-font-size: calc(28rem / 16) !default;
$h1-line-height: calc(2rem/$h1-font-size) !default;

$h2-font-size: calc(20rem / 16) !default;
$h2-line-height: calc(2rem/$h2-font-size) !default;

$h3-font-size: calc(16rem / 16) !default;
$h3-line-height: calc(1.5rem/$h3-font-size) !default;

$h4-font-size: $font-size-base  !default;
$h4-line-height: calc(1.25rem/$h4-font-size) !default;

$h5-font-size: calc(12rem / 16) !default;
$h5-line-height: calc(1rem/$h5-font-size) !default;

$h6-font-size: calc(10rem / 16) !default;
$h6-line-height: calc(1rem/$h6-font-size) !default;

$small-font-size: $h5-font-size  !default;
$blockquote-font-size: $font-size-base  !default;

$code-font-size: $h5-font-size  !default;
$code-line-height: 1.25rem !default;
$code-color: $primary  !default;

$lead-font-size: $font-size-base  !default;
$lead-font-weight: $font-weight-normal  !default;

$hr-opacity: .1 !default;
$hr-margin-y: 2rem !default;

$caret-spacing: .4em !default;

//Sizing
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4.5) !default;

$size-spacers: (
  auto: auto,
  px: 1px,
  full: 100%) !default;


$size-values: (
  1: 1%,
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  auto: auto) !default;

$grid-breakpoints: (
  xs: 0,
  sm: 33rem,
  md: 48rem,
  lg: 64rem,
  xl: 75.25rem) !default;

$container-max-widths: (
  sm: 32.5rem,
  md: 47.5rem,
  lg: 64rem,
  xl: 75.25rem) !default;

$container-variations: (
  tight: 30rem,
  narrow: 45rem,
  ) !default;

$embed-responsive-aspect-ratios: (
  "21by9": (x: 21,
    y: 9),
  "16by9": (x: 16,
    y: 9),
  "4by3": (x: 4,
    y: 3),
  "1by1": (x: 1,
    y: 1),
  '2by1': (x: 2,
    y: 1)) !default;

$content-padding-y: 1.25rem !default;


//alerts
$alert-link-font-weight: $font-weight-bold  !default;
$alert-bg-level: -8 !default;
$alert-border-level: -6 !default;
$alert-color-level: 8 !default;

//breadcrumb
$breadcrumb-variants: (
  dots: "·",
  arrows: "›",
  bullets: "\02022",
  ) !default;

//badges
$badge-padding-y: .125rem !default;
$badge-padding-x: .25rem !default;
$badge-font-size: $h6-font-size  !default;
$badge-font-weight: $font-weight-bold  !default;
$badge-empty-size: .5rem !default;

//buttons
$input-color: $body-color  !default;
$input-btn-line-height: calc(20/14) !default;
$input-btn-font-size: $font-size-base  !default;
$input-btn-padding-y: .5rem - .0625rem !default;

$input-btn-font-size-sm: $h5-font-size  !default;
$input-btn-padding-x-sm: .5rem !default;
$input-btn-padding-y-sm: .125rem !default;

$input-btn-font-size-lg: $h2-font-size  !default;
$input-btn-padding-x-lg: 1.5rem !default;
$input-btn-padding-y-lg: .5rem !default;

$input-height: null !default;
$input-height-sm: null !default;
$input-height-lg: null !default;
$input-color: inherit !default;
$input-focus-color: inherit !default;

//buttons
$btn-padding-x: 1rem !default;
$btn-font-weight: $font-weight-bold  !default;
$btn-border-radius: 3px !default;

//cards
$card-border-color: $border-color  !default;
$card-border-radius: $border-radius  !default;
$card-cap-bg: $min-black  !default;

$card-cap-padding-x: 1.25rem !default;
$card-cap-padding-y: .75rem !default;

$card-active-border-color: $primary  !default;
$card-status-size: $border-width-wide  !default;
$card-group-margin: 1.5rem !default;
$card-group-margin-sm: .75rem !default;

$card-shadow: rgba($dark, .04) 0 2px 4px 0 !default;
$card-shadow-hover: rgba($dark, .16) 0 2px 16px 0 !default;

$cards-grid-gap: 1.25rem !default;
$cards-grid-gap-sm: .75rem !default;
$cards-grid-breakpoint: lg !default;

//carousel
$carousel-control-color: $white  !default;
$carousel-control-icon-width: 1.5rem !default;
$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$carousel-control-color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><polyline points='15 18 9 12 15 6'></polyline></svg>") !default;
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$carousel-control-color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><polyline points='9 18 15 12 9 6'></polyline></svg>") !default;


//close
$close-font-weight: $font-weight-normal  !default;
$close-font-size: 1.5rem !default;

//dropdown
$dropdown-item-padding-x: 1rem !default;
$dropdown-font-size: $font-size-base  !default;
$dropdown-divider-bg: $border-color  !default;
$dropdown-padding-y: .5rem !default;
$dropdown-link-hover-bg: $hover-bg  !default;
$dropdown-link-hover-color: inherit !default;
$dropdown-spacer: 1px !default;
$dropdown-min-width: 14rem !default;

$dropdown-link-active-color: $primary  !default;
$dropdown-link-active-bg: $active-bg  !default;

$dropdown-border-color: $border-color  !default;
$dropdown-menu-max-width: 25rem !default;

//grid
$grid-gutter-width: 1.5rem !default;
$container-padding-x: .75rem !default;

//loader
$loader-size: 2.5rem !default;

//lists
$list-group-item-padding-y: .5rem !default;
$list-group-item-padding-x: .75rem !default;

//modals
$modal-backdrop-opacity: .24 !default;
$modal-backdrop-bg: $dark  !default;
$modal-backdrop-blur: 2px !default;

$modal-fade-transform: translate(0, -1rem) !default;

$modal-content-border-color: $border-color-light  !default;
$modal-content-bg: $gray-50  !default;
$modal-content-border-radius: $border-radius  !default;

$modal-header-padding: 1.5rem !default;
$modal-header-height: 3rem !default;
$modal-header-border-width: 1px !default;
$modal-header-border-color: $border-color-light  !default;
$modal-header-bg: $primary  !default;
$modal-inner-padding: 1rem !default;
$modal-footer-border-width: 0 !default;
$modal-footer-margin-between: .75rem !default;

$modal-xl: 1140px !default;
$modal-lg: 720px !default;
$modal-md: 540px !default;
$modal-sm: 380px !default;

//nav
$nav-link-padding-y: .25rem !default;
$nav-link-padding-x: .75rem !default;

//navbar
$navbar-height: 3.5rem !default;
$navbar-padding-y: .25rem !default;

$navbar-light-color: rgba($body-color, $text-muted-opacity) !default;
$navbar-light-brand-color: $body-color  !default;
$navbar-light-active-color: $body-color  !default;
$navbar-light-disabled-color: rgba($body-color, .3) !default;

$navbar-dark-color: rgba($white, $text-muted-opacity) !default;
$navbar-dark-brand-color: $white  !default;
$navbar-dark-active-color: $white  !default;
$navbar-dark-disabled-color: rgba($white, .3) !default;


$navbar-brand-padding-y: $nav-link-padding-y  !default;
$navbar-brand-image-height: 2rem !default;
$navbar-brand-margin-right: 0 !default;
$navbar-brand-font-size: $h3-font-size  !default;
$navbar-brand-font-weight: $font-weight-bold  !default;

$navbar-toggler-font-size: 1rem !default;
$navbar-toggler-padding-x: 0 !default;
$navbar-toggler-padding-y: 0 !default;
$navbar-toggler-animation-time: .2s !default;
$navbar-toggler-focus-width: 0 !default;

$navbar-nav-link-padding-x: $nav-link-padding-x  !default;

//sidebar
$sidebar-width: 15rem !default;

//popover
$popover-border-color: $border-color  !default;

//footer
$footer-bg: $white  !default;
$footer-border-color: $border-color  !default;
$footer-border-color: $border-color  !default;


//pagination
$pagination-border-width: 0 !default;
$pagination-padding-y: .25rem !default;
$pagination-padding-x: .25rem !default;
$pagination-color: $text-muted  !default;
$pagination-bg: transparent !default;
$pagination-disabled-bg: transparent !default;
$pagination-disabled-color: rgba($text-muted, .5) !default;


//steps
$steps-border-width: 2px !default;
$steps-color-inactive: #f3f5f5 !default;

//spinner
$spinner-width: 1.5rem !default;
$spinner-height: 1.5rem !default;
$spinner-width-sm: 1rem !default;
$spinner-height-sm: 1rem !default;
$spinner-border-width: 2px !default;
$spinner-border-width-sm: 1px !default;

//tables
$table-head-border-color: $border-color  !default;
$table-striped-order: even !default;
$table-accent-bg: $min-black  !default;

//toasts
$toast-border-color: $border-color  !default;
$toast-header-color: $text-muted  !default;

//progress
$progress-bg: $light-mix  !default;
$progress-height: .5rem !default;

//ribbons
$ribbon-margin: .25rem !default;


$list-group-border-color: $border-color  !default;
$list-group-action-color: inherit !default;
$list-group-bg: inherit !default;

$input-disabled-bg: $gray-100  !default;
$input-border-color: $border-color  !default;
$input-placeholder-color: $text-muted  !default;

$input-group-addon-bg: $min-black  !default;
$input-group-addon-color: $text-muted  !default;

$input-border-radius: $border-radius  !default;


//Forms
$form-check-input-width: 1rem !default;
$form-check-padding-left: $form-check-input-width + .5rem !default;
$form-check-input-bg: $white  !default;
$form-check-input-border: 1px solid $border-color  !default;
$form-check-input-border-radius: $border-radius  !default;
$form-check-input-checked-bg-size: 1rem !default;

$form-check-input-checked-color: $white  !default;
$form-check-input-checked-bg-repeat: repeat !default;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8.5l2.5 2.5l5.5 -5.5'/></svg>") !default;
$form-check-input-checked-bg-image-dark: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'><path fill='none' stroke='#{$dark}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8.5l2.5 2.5l5.5 -5.5'/></svg>") !default;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><circle r='3' fill='#{$form-check-input-checked-color}' cx='8' cy='8' /></svg>") !default;

$form-check-input-checked-border-color: $border-color  !default;

$form-select-indicator-color: opacify($border-color, .24) !default;

$form-switch-width: 1.75rem !default;
$form-switch-padding-left: $form-switch-width + .5rem !default;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$border-color}'/></svg>") !default;
$form-switch-bg-size: auto !default;

$form-range-track-height: .25rem !default;
$form-range-track-bg: $light-mix  !default;
$form-range-thumb-border: 2px solid $white  !default;
$form-range-thumb-height: 1rem !default;
$form-range-thumb-focus-box-shadow-width: .125rem !default;

$form-feedback-icon-valid: str-replace(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='" + $green + "' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><polyline points='20 6 9 17 4 12'></polyline></svg>"), "#", "%23") !default;
$form-feedback-icon-invalid: str-replace(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='" + $red + "' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><line x1='18' y1='6' x2='6' y2='18'></line><line x1='6' y1='6' x2='18' y2='18'></line></svg>"), "#", "%23") !default;

$caret-width: .36em !default;

//Flags
$generate-flags: true !default;
$flag-sizes: (
  "md": 2rem,
  "lg": 3rem,
  "xl": 4rem) !default;

//Payments
$payment-sizes: (
  "sm": 1.5rem,
  "lg": 3rem,
  "xl": 4rem) !default;

$enable-social-colors: true !default;
$enable-ghost-buttons: true !default;
$enable-extra-colors: true !default;
$enable-gradients: false !default;
$enable-navbar-vertical: true !default;
$enable-dark-mode: true !default;