.table_DSVanDe {
  thead tr th {
    text-align: center;
    vertical-align: middle;
    background-color: rgb(0, 111, 202);
    color: #fff;
  }
}

.table-sticky-dk {
  position: relative;
  height: 23rem;
  overflow: auto;

  table {
    thead {
      position: sticky;
      top: 0;
      z-index: 10;

      tr {
        th {
          top: 0;
          position: sticky;
          z-index: 10;
          color: #fff;
          text-align: center;
          vertical-align: middle;
          background-color: #006fca;
        }

        td {
          top: 0;
          position: sticky;
          z-index: 10;
          text-align: center;
          vertical-align: middle;
          background-color: lightslategray;
          color: white;
          font-weight: bold;
        }
      }
    }

    tbody {
      position: relative;

      tr {
        td {
          vertical-align: middle;
          position: sticky;
        }

        th {

          &:nth-of-type(1) {
            top: 0;
            left: 0;
            position: sticky;
            background-color: #d2dada;
            background-clip: padding-box;
            z-index: 9;
          }

          &:nth-of-type(2) {
            top: 0;
            left: 31px !important;
            position: sticky;
            background-color: #d2dada;
            background-clip: padding-box;
            z-index: 9;
          }

        }

      }
    }

    input[type="number"] {
      min-width: 100px;
    }
  }
}

#playPauseButton {
  position: absolute;
  bottom: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgb(0 0 0 / 30%);
  cursor: pointer;
  border-radius: 5em;

  .border-playing {
    width: 100%;
    height: 100%;
    border: 1px solid #41fff5;
    border-radius: 20px;
  }

  &.is--playing .border-playing {
    border-top: none;
    border-bottom: none;
    animation: spin 1.5s ease-in-out infinite;
  }

  .play {
    position: absolute;
    top: 6px;
    left: 12px;
    box-sizing: border-box;
    height: 15px;
    border-color: transparent transparent transparent #41fff5;
    transition: 100ms all ease;
    will-change: border-width;
    cursor: pointer;
    border-style: solid;
    border-width: 9px 0px 10px 11px;
  }

  &.is--playing .play {
    left: 11px;
    border-style: double;
    border-width: 0px 0 0px 11px;
    transform: translate(-1px, 1px);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// progress
.progress-7 {
  height: 10px;
  -webkit-mask: radial-gradient(circle closest-side, #000 94%, #0000) left/20% 100%;
  background: linear-gradient(#000 0 0) left/0% 100% no-repeat #ddd;
  animation: p7 2s infinite steps(6);
}

@keyframes p7 {
  100% {
    background-size: 120% 100%
  }
}

// animation record
.mic-animation-container {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mic-animation-container svg {
  width: 50px;
  height: 50px;
}

.animation-outer {
  width: 50px;
  height: 50px;
  -webkit-transform: scale(0);
  border-radius: 100%;
  position: absolute;
  background-color: #e74c3c;
  z-index: 1;
  transition: 1.5s all ease;
  -webkit-animation: woong 1.5s infinite;
  -moz-animation: woong 1.5s infinite;
  -o-animation: woong 1.5s infinite;
  animation: woong 1.5s infinite;
}

@-o-keyframes woong {
  0% {
    -webkit-trasform: scale(1.2);
  }

  50% {
    -webkit-transform: scale(1.8);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(2.4);
    opacity: 0;
  }
}

@-webkit-keyframes woong {
  0% {
    -webkit-trasform: scale(1.2);
  }

  50% {
    -webkit-transform: scale(1.8);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(2.4);
    opacity: 0;
  }
}

@-moz-keyframes woong {
  0% {
    -webkit-trasform: scale(1.2);
  }

  50% {
    -webkit-transform: scale(1.8);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(2.4);
    opacity: 0;
  }
}

@keyframes woong {
  0% {
    -webkit-trasform: scale(1.2);
  }

  50% {
    -webkit-transform: scale(1.8);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(2.4);
    opacity: 0;
  }
}

.time-record {
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: .2rem;
  width: 100%;
  text-align: center;
  margin-bottom: 0px;
}

%hover-btn {
  cursor: pointer;
  transform: scale(1.1)
}

.btn-play {
  &:hover {
    @extend %hover-btn;
  }

  background: linear-gradient(#f9c781, #fde291)
}

.btn-resume {
  &:hover {
    @extend %hover-btn;
  }

  background: linear-gradient(#7450ff, #9076ff)
}