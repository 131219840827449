.hidden-input {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    width: 100%;
    .hidden-input-control {
        opacity: 0;
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        &:focus, &:focus-within {
            opacity: 1;
            + .hidden-input-label { visibility: collapse; }
        }
    }
    .hidden-input-label {
        min-height: calc(1.42857em + 0.25rem + 2px);
        text-align: justify;
    }
}