.ToolbarContainer {
    background: #ffffff;
    border-bottom: 2px solid #999999;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin: -20px -17px 20px 0px;
    position: sticky;
    top: 0;
    z-index: 1;

    &.sticky {
        border-top: 2px solid transparent;
        box-shadow: 0px 3px 5px -3px rgba(#333333, 0.5);
    }
}

.template-email .ToolbarContainer {
    margin: 0px 0px 10px 0px !important;
}

.toolbar-note {
    display: flex;

    .icon {
        border-radius: 5px;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333333;

        &.disabled {
            color: rgba(#333333, 0.5);
            pointer-events: none;
        }
    }

    .icon:hover {
        background: #333333;
        color: #ffffff;
        cursor: pointer;
    }

    .divider {
        border: none;
        border-left: 2px solid rgba(#333333, 0.2);
        margin: 2px 10px;
    }

    .icon+.icon {
        margin-left: 4px;
    }
}

.circle-animation {
    background: #1b6abb;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    overflow: hidden;
    align-self: center;
}

.ping-animation {
    -webkit-animation: ping-animation 0.8s ease-in-out infinite both;
    animation: ping-animation 0.8s ease-in-out infinite both;
}

/* ----------------------------------------------
   * Generated by Animista on 2022-6-23 9:25:32
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info. 
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */

/**
   * ----------------------------------------
   * animation ping
   * ----------------------------------------
   */
@-webkit-keyframes ping-animation {
    0% {
        -webkit-transform: scale(0.2);
        transform: scale(0.2);
        opacity: 0.8;
    }

    80% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(2.2);
        transform: scale(2.2);
        opacity: 0;
    }
}

@keyframes ping-animation {
    0% {
        -webkit-transform: scale(0.2);
        transform: scale(0.2);
        opacity: 0.8;
    }

    80% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(2.2);
        transform: scale(2.2);
        opacity: 0;
    }
}