.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1rem;
  text-align: center;

  @include media-breakpoint-up(md) {
    padding: 3rem;
  }

  .empty-icon {
    margin: 0 0 1rem;
    font-size: 2rem;
    font-weight: $font-weight-normal;
    line-height: 1;
  }

  .empty-title,
  .empty-subtitle {
    margin: 0 0 .5rem;
  }

  .empty-action {
    margin-top: 1.5rem;
  }
}

.empty-bordered {
  border: 1px solid $border-color;
  border-radius: $border-radius;
}
