.code{
    font-size: 73.5%;
    color: #adb800;
    word-break: break-word;
    font-family: SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;
}
.img{
    padding: 0.1rem !important;
}
.img-pd{
    padding-left: 0px;
}
.mr-12{
    margin-right: 12px;
    
}
.pd-check{
    padding: 0px 10px 0px !important;
}