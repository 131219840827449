@mixin subheader($include-color: true) {
  font-size: $h6-font-size;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  letter-spacing: .04em;
  line-height: $h6-line-height;

  @if ($include-color) {
    color: $text-muted;
  }
}

@mixin scrollbar($is-dark: false) {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    transition: .3s background;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;

    @if ($is-dark) {
      background: transparent;
    } @else {
      background: #8ca4ef;
    }
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: $gray-500;

    @if ($is-dark) {
      background: mix(#ffffff, $dark, 20%);
    } @else {
      background: #7fa8f1;
    }
  }
}


@mixin autodark-image {
  filter: brightness(0) invert(1);
}

@mixin dark-mode {
  @if $enable-dark-mode {
    $selector: &;

    @media not print {
      @if ($selector) {
        .theme-dark & {
          @content
        }
      } @else {
        .theme-dark {
          @content
        }
      }
    }

    @media not print and (prefers-color-scheme: dark) {
      @if ($selector) {
        .theme-dark-auto & {
          @content
        }
      } @else {
        .theme-dark-auto {
          @content
        }
      }
    }
  }
}

@mixin button-ghost-variant(
  $background,
  $border,
  $color,
  $hover-background: darken($background, 7.5%),
  $hover-border: darken($border, 10%),
  $hover-color: color-contrast($hover-background),
  $active-background: darken($background, 10%),
  $active-border: darken($border, 12.5%)
) {
  color: $color;
  @include gradient-bg($background);
  border-color: $border;
  @include box-shadow($btn-box-shadow);

  @include hover() {
    color: color-yiq($hover-background);
    @include gradient-bg($hover-background);
    border-color: $hover-border;
  }

  &:focus,
  &.focus {
    color: color-yiq($hover-background);
    @include gradient-bg($hover-background);
    border-color: $hover-border;
    @if $enable-shadows {
      @include box-shadow($btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5));
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5);
    }
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: color-yiq($background);
    background-color: $background;
    border-color: $border;
    // Remove CSS gradients if they're enabled
    @if $enable-gradients {
      background-image: none;
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;
    @if $enable-gradients {
      background-image: none; // Remove the gradient for the pressed/active state
    }
    border-color: $active-border;

    &:focus {
      @if $enable-shadows and $btn-active-box-shadow != none {
        @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5));
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5);
      }
    }
  }
}
