.result-item {
    &:hover {
        cursor: pointer;
        background: lavender;
        transform: scale(1.015);
    }
}

.card-request-hover {
    cursor: pointer;
    box-shadow: '2px 2px 3px #ccc';

    &:hover {
        background-color: #BDCBE6;
        color: #000;
    }
}

.card-request-disable {
    box-shadow: '2px 2px 3px #ccc';
    background-color: #ddd;
}

.active-request {
    background-color: #BDCBE6;
    color: #000;
}

.modal-result {
    .MuiTabs-root {
        .MuiTabs-scroller {
            .MuiTabs-flexContainer {
                .MuiButtonBase-root {
                    font-weight: bold;

                    .MuiTab-wrapper {
                        align-items: center;
                    }
                }
            }
        }
    }
}

.card-rechart-info {
    border: outset;
    box-shadow: 0px 0px 0px 0px #000;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    border-radius: 0.5rem;

    .card-body-rechart-info {
        font-size: 13px;
    }

    .label-rechart-custom {
        position: absolute;
        top: -12px;
        left: 10px;
        padding: 2px;
        background: linear-gradient(to bottom, #EEEEEE 25%, #ffffff 75%);
        border-radius: 3px;
        color: #006fca;
        font-style: italic;
        font-size: 12px;
        font-weight: bold;
        z-index: 1;
    }

    .ul-legend-chart {
        list-style-type: none;

        // position: absolute;
        // top: 50%;
        // transform: translate(-50%, -50%);
        // left: 50%;
        // width: 100%;
        // padding: 15px;
        // padding-left: 35px;
        // height: 100%;
        // overflow: auto;
        li {
            position: relative;
            display: flex;
            align-items: center;

            .span-badge-custom {
                padding: 5px;
                border-radius: 150%;
                position: absolute;
                left: -20px;
            }
        }
    }

    &:last-child {
        height: 175px;
        margin-bottom: 0px;

        .card-body {
            overflow: auto;
        }
    }
}

.con-dau-info {
    position: absolute;
    top: 170px;
    left: 45px;
    font-size: 15px;
    padding: 10px;
    width: 90px;
    height: 90px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border: 1px solid;
    border-radius: 50%;
    color: red;
    font-weight: bold;
    border: 2px solid red;
}

.not-found-data {
    height: 300px;

    .info {
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translate(-50%);

        .text {
            font-size: 21px;
            font-weight: bold;
            font-style: italic;
            color: #aaa;
        }
    }
}


@keyframes cssAnimation {
    to {
        visibility: visible;
        height: 325px;
    }
}

.prob-form {
    transition: all 0.6s !important;

    & .prop-info {
        transition: all 0.6s !important;
    }

    & .list-thamDus {
        overflow-y: auto;
        overflow-x: hidden;
        height: 300px;

    }

    & .prop-thamDus {
        animation: cssAnimation 0s 0.6s forwards;
        visibility: hidden;
        height: 0;

        & .devider {
            border-left: 1px solid green;
            height: 20rem;
            position: absolute;
            top: 6px;
            left: -3px;
            border-left-color: darkgray;
        }
    }

    & .thamDu {
        box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 10%) !important;
        cursor: pointer;

        &:hover {
            transform: scale(1.02);
        }
    }
}