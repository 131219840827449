.stamp {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  min-width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;

  padding: 0 .25rem;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  color: $text-muted;
  text-align: center;
  background: $gray-200;
  border-radius: 3px;

  .icon {
    font-size: 1.25rem;
    vertical-align: middle;
  }
}
