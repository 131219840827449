.MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root {
  .MuiTab-wrapper {
    align-items: start;
  }

  &.MuiTab-textColorInherit.Mui-selected {
    background-color: #006fca;
    color: white;
  }
}

.start-meet {
  overflow-x: hidden;
  overflow-y: auto;

  .form-control-organId {
    margin: 0px;
  }

  .form-control-batDau {
    margin: 0px;
  }

  .form-control-ketThuc {
    margin: 0px;
  }
}

.static-state {
  label {
    &#KhoiTao {
      color: #ca9400;
    }

    &#HoanThanh {
      color: #31f700;
    }

    font-size: 15px;
    font-weight: bold;
    text-align: center;
    border-radius: 0.2rem;
    padding: 3px;
  }
}

.pulsate-bck {
  -webkit-animation: pulsate-bck 0.5s ease-in-out infinite both;
  animation: pulsate-bck 0.5s ease-in-out infinite both;

  label {
    background: #006fca;
    border-radius: 0.2rem;
    padding: 3px;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
    color: white;
  }
}

@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.overlay-accessing {
  position: fixed;
  /* Sit on top of the page content */
  display: none;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 999;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
  backdrop-filter: blur(4px);
  border-radius: 0.3rem;
}

.overlay-connecting {
  position: fixed;
  /* Sit on top of the page content */
  display: none;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 1200;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
}

.progress-connect {
  width: 4rem;
  height: 5px;
  -webkit-mask: radial-gradient(circle closest-side, #c4bdbd 100%, #0000) left/20% 100%;
  background: linear-gradient(#939a93 0 0) left/0% 100% no-repeat #fff;
  animation: p7 2s infinite steps(6);
  display: inline-flex;
}

.dot-active {
  height: 13px;
  width: 13px;
  background-color: #006fca;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 0.3em;
  top: 40%;
}