$google-font: 'Mulish';
$blue: #006fca;
$green: #799b00;
$ademax: #f4572c;
$maincolor:$ademax;

$navbar-height: 2.5rem;

$embed-responsive-aspect-ratios: (
  (21,9),
  (16,9),
  (4,3),
  (1,1),
  (2,1)
);