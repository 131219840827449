.col-separator {
  border-left: 1px solid $border-color;
}

.container {

  @each $variation,
  $size in $container-variations {
    &-#{$variation} {
      @include make-container();
      max-width: $size;
    }
  }
}

@each $name,
$value in (0: 0, sm: .375rem, md: 1.5rem, lg: 3rem) {
  .row-#{$name} {
    margin-right: (-$value);
    margin-left: -($value);

    >.col,
    >[class*="col-"] {
      padding-right: $value;
      padding-left: $value;
    }

    .card {
      margin-bottom: 2 * $value;
    }
  }
}

.row-deck {

  >.col,
  >[class*="col-"] {
    display: flex;
    align-items: stretch;

    .card {
      flex: 1 1 auto;
    }
  }
}

.row-cards {
  margin-left: calc(-#{$cards-grid-gap} / 2);
  margin-right: calc(-#{$cards-grid-gap} / 2);

  .row-cards {
    flex: 1;
  }

  >* {
    padding-left: calc($cards-grid-gap / 2);
    padding-right: calc($cards-grid-gap / 2);
  }

  @include media-breakpoint-down($cards-grid-breakpoint) {
    margin-left: calc(-#{$cards-grid-gap-sm} / 2);
    margin-right: calc(-#{$cards-grid-gap-sm} / 2);

    >* {
      padding-left: calc($cards-grid-gap-sm / 2);
      padding-right: calc($cards-grid-gap-sm / 2);
    }
  }
}