@mixin transition {
    @for $i from 1 through 50 {
      &:nth-child(#{$i}) {
        transition: transform 1s #{$i * .08}s cubic-bezier(.29,1.4,.44,.96);
      }
    }
  }
  
  @mixin alpha-attribute($attribute, $color, $background) {
    $percent: alpha($color) * 100%;
    $opaque: opacify($color, 1);
    $solid-color: mix($opaque, $background, $percent);
    #{$attribute}: $solid-color;
    #{$attribute}: $color;
  }

  .drawer-document{
    .text-blue{color: #edf2db !important }
    html {font-family: 'Lato', sans-serif;}
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }
      $main: #1184F0;
      $text: #FEFEFE;
      $contrast: darken($main, 10%);
      .msg {
        font-family: 'Pacifico', cursive;
        font-size: 8vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        align-items: center;
        color: $text;
        background-color: $main;
      }
      
      .features {
        font-family: 'Lato', sans-serif;
        font-size: 1rem;
        text-transform: uppercase;
        li {
          width: 100%;
          text-align: center;
          padding: .25em;
        }
      }
      
      .drawer-list {
        z-index: 1001;
        position: fixed;
        right: 0;
        top: 0;
        height: 100vh;
        width: 16rem !important;
        overflow-x: hidden;
        transform: translate(100vw, 0);
        /* ie workaround */
        -ms-transform: translatex(-100vw);
        box-sizing: border-box;
        pointer-events: none;
        padding-top: 3rem;
        transition: width 475ms ease-out, transform 450ms ease, border-radius .8s .1s ease;
        border-bottom-left-radius: 100vw;
        @include alpha-attribute('background-color', rgba($contrast, 0.9), white);
        @media (min-width: 768px) {
          width: 40vw;
        }
        .p-2 ul.MuiTreeView-root{ 
          overflow-y: auto;
          height: 80vh;
          .MuiSvgIcon-colorSecondary{ color: wheat; }
          .MuiSvgIcon-colorDisabled { color: black; }
        }
        ul {
          width: 100%;
          margin: 0px 0.5rem;
          padding: 0.1rem;
          overflow-x: hidden;
          pointer-events: auto;
        }
        li {
          list-style: none;
          pointer-events: auto;
          white-space: nowrap;
          box-sizing: border-box;
          transform: translatex(100vw);
          /* ie workaround */
          -ms-transform: translatex(-100vw);
          a {
            text-decoration: none;
            color: $text;
            text-align: center;
            display: block;
            padding: 1rem;
            font-size: calc(24px - .5vw);
            @media (min-width: 768px) {
              text-align: right;
              padding: .5rem;
            }
            &:hover {
              cursor: pointer;
              @include alpha-attribute('background-color', rgba($main, 0.5), white);
            }
          }
        }
      }
      
      input.hamburger {
        display: none;
        &:checked {
          & ~ .drawer-list {
            color: white;
             transform: translatex(0);
              border-bottom-left-radius: 0;
              li {
                transform: translatex(0);
                @include transition;
                a {
                  padding-right: 15px;
                }
              }
          }
          & ~ label {
            > i {
              background-color: transparent;
              transform: rotate(90deg);
              &:before {
                transform: translate(-50%, -50%) rotate(315deg);
              }
              &:after {
                transform: translate(-50%, -50%) rotate(-315deg);
              }
            }
            close {
              color: $text;
              width: auto;
            }
            open {
              color: rgba(0, 0, 0, 0);
              width: 0;
            }
          }
        }
      }
      
      label.hamburger {
        z-index: 9999;
        position: relative;
        display: block;
        height: 1rem;
        width: 1rem;
        &:hover {
          cursor: pointer;
        }
        text {
          close,
          open {
            text-transform: uppercase;
            font-size: .8em;
            text-align: center;
            position: absolute;
            transform: translateY(50px);
            text-align: center;
            overflow: hidden;
            transition: width .25s .35s, color .45s .35s;
          }
          close {
            color: rgba(0, 0, 0, 0);
            right: 0;
            width: 0;
          }
          open {
            color: $text;
            width: auto;
          }
        }
        > i {
          position: absolute;
          width: 100%;
          height: 2px;
          top: 50%;
          background-color: $text;
          pointer-events: auto;
          transition-duration: .35s;
          transition-delay: .35s;
          &:before,
          &:after {
            position: absolute;
            display: block;
            width: 100%;
            height: 2px;
            left: 50%;
            background-color: $text;
            content: "";
            transition: transform 0.35s;
            transform-origin: 50% 50%;
          }
          &:before {
            transform: translate(-50%, -14px);
          }
          &:after {
            transform: translate(-50%, 14px);
          }
        }
      }
      
      label.hamburger {
        position: fixed;
        top: 3rem;
        right: 2rem;
      }
      
      /**
          SCROLLBAR STYLE FOR IE
      */
      
      .icon {
        display: inline-block;
        width: 5vw;
        height: 4vw;
        stroke-width: 0;
        stroke: currentColor;
        fill: currentColor;
      }
  }
  
  